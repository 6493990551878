@import '~antd/dist/antd.css';
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* //reportPage找经销商-报货表格调整 */
.ant-table.ant-table-small .ant-table-tbody > tr > td, 
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td{
padding: 0 4px;
}
.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 20px;
}
/* .ant-select:not(.ant-select-customize-input) .ant-select-selector {
border: none;
} */
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
height: 24px;
}
.ant-input-search {
  border: 1px solid #e8e8e8;
  height: 24px;
}
.ant-input-search .ant-input {
border: none;
padding: 0 4px;
/* text-align: center; */
}
.ant-input-search .ant-btn {
  padding: 0 6px;
  height: 24px;
  line-height: 21px;
  top: -1px;
  right: -2px;
}
.ant-btn {
  padding: 0 6px;
  height: 24px;
  line-height: 21px;
}

.ant-input-search .ant-input {
height: 21px;
}
.ant-input-search .ant-input-affix-wrapper {
  padding: 0;
  border: none;
}
.ant-form-item-control-input {
  min-height: 24px;
}

.ant-table.ant-table-small .ant-table-title, 
.ant-table.ant-table-small .ant-table-footer, 
.ant-table.ant-table-small .ant-table-thead > tr > th, 
.ant-table.ant-table-small .ant-table-tbody > tr > td, 
.ant-table.ant-table-small tfoot > tr > th, 
.ant-table.ant-table-small tfoot > tr > td {
  padding: 0 4px;
}
.ant-table-thead > tr > th, 
.ant-table-tbody > tr > td, 
.ant-table tfoot > tr > th, 
.ant-table tfoot > tr > td {
  padding: 0;
}

.ant-input {
  padding: 0 4px;
  /* text-align: center; */
  }

/* form输入框下bottom */
.ant-form-item {
  margin-bottom: 10px;
  /* align-items: center; */
}
.anticon-star {
  vertical-align: -0.125em !important;
}
.ant-form-item-label > label {
  height: 24px;
}
.ant-picker {
  padding: 0 4px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item, 
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 22px;
  transition: all 0.3s;
}

.ant-input-number-input {
  height: 24px;
  line-height: 24px;
}

/* 分页 */
.ant-pagination-item {
  min-width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 22px;
}
.ant-pagination-options .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #d9d9d9;
  height: 24px;
  line-height: 24px;
}
.ant-pagination-prev, 
.ant-pagination-next, .ant-pagination-jump-prev, 
.ant-pagination-jump-next {
  min-width: 24px;
  height: 24px;
  line-height: 24px;
}
/* 分页结束 */
/* //switch开关 */
.ant-switch {
  min-width: 36px;
    height: 18px;
    line-height: 16px;
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 14px - 2px);
}
.ant-switch-handle {
  width: 14px;
  height: 14px;
}

/* //下拉多选 */
.ant-select-multiple .ant-select-selection-item {
  height: 18px;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 16px;
}

/* 左侧导航padding-left */
/* .ant-menu-dark.ant-menu-inline .ant-menu-item, .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  padding-left: 12px !important;
} */
.ant-menu {
  margin-left: -10px;
}
/* 描述 */
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label, .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content{
  padding: 1px 16px;
}
a.ant-btn {
    line-height: 24px;
}

/* //弹出框限制高度 */
.ant-modal-body {
  max-height: 400px;
  overflow-y: scroll;
}

/* 下拉框宽度 */
.ant-select {
  width: 100%;
}
/* 表格里的标记 */

.ant-ribbon {
  height: 18px;
  padding: 0 6px;
  line-height: 18px;
  font-size: 10px;
  right: -10px; 
  top: -4px;
}


/* 在消除575px的样式 */
@media (max-width: 575px) {
  .ant-layout-content{
    min-width: 980px;
  }
  html, body {
    overflow-y:hidden;
    min-width: 1140px;
  }
  .ant-form .ant-form-item .ant-form-item-label, .ant-form .ant-form-item .ant-form-item-control {
    flex: none !important;
    max-width: none;
  }
  .ant-form-item .ant-form-item-label {
    padding: 0;
  }
  .ant-form-item .ant-form-item-label > label {
    margin-right: 8px;
  }
}

@media print {
  .no-print {
    display: none;
  }
}
@media screen {
  .print {
    display: none;
  }
}